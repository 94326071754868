import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gallery from '@browniebroke/gatsby-image-gallery';
import { graphql } from 'gatsby';
export const query = graphql`
  query redRoom {
    images: allFile(
      filter: { relativeDirectory: { eq: "red-room-gen-art" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 450)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Red Room Generative Art",
  "date": "2021-02-06T00:00:00.000Z"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery images={props.data.images.edges.map(({
      node
    }) => ({
      ...node.childImageSharp,
      caption: node.name,
      thumbAlt: node.name
    }))} mdxType="Gallery" />
    <p>{`Generative art for Red Room from Twin Peaks using `}<a parentName="p" {...{
        "href": "https://tinkersynth.com/"
      }}>{`tinkersynth`}</a>{` with touches`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      